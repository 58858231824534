<template>
  <div>
    <b-card
      overlay
      img-src="https://yuzudigital.com/news_mock.png"
      text-variant="white"
      footer-bg-variant="white"
      footer-text-variant="dark"
    >
      <!-- <div class="text-right">
        <b-button href="/reason-detail" variant="primary" style="padding: 2px;">
          <p class="m-0" style="font-size: 0.8rem;">ลงทะเบียน</p>
        </b-button>
      </div> -->
      <template #footer>
        <p style="color: #000;">
          <b> พาดหัวข่าวประชาสัมพันธ์ </b>
        </p>
        <p style="color: #000; font-size: 0.7rem">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisUt enim ad
          minim
        </p>
        <div>
          <button
            class="btn btn-lg btn-primary btn-block form-group"
            style="color: #fff;"
          >
            <span>
              <b>อ่านต่อ</b>
            </span>
          </button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
export default {
  methods: {
    async reasonDetail() {
      location.href = "/reason-detail";
    },
  },
};
</script>
