<template>
  <page-content>
    <img src="@/assets/mock/header.png" style="width: 100%" />
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <br />
    <br />
    <div style="padding: 100px;">
      <h3 class="mt-4" style="padding-left: 10px">
        <b>หลักสูตร จาก E-Learning</b>
      </h3>

      <div class="row mb-4">
        <div class="col-10">
          <b-form-group class="px-2">
            <b-input-group class="input-group-seamless">
              <b-form-input placeholder="ค้นหาหลักสูตร"></b-form-input>

              <b-input-group-text slot="prepend">
                <font-awesome-icon class="mx-auto" icon="search" />
              </b-input-group-text>

              <!-- <b-input-group-text  slot="append">
              <a href="javascript:void(0)" >
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text> -->
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-button variant="primary" block size="sm" @click="onKeywordSearched"
            >ค้นหา</b-button
          >
        </div>
      </div>
      <div
        class="row mb-4"
        style="padding-left: 5%; padding-right: 5%; margin-top: -30px"
      >
        <div class="col-3">
          <a>
            <p
              class="mt-4 text-center"
              align-items-center
              style="
                text-decoration: underline;
                color: #000;
                text-decoration-color: #fcc820;
                text-decoration-thickness: 3px;
              "
              @click="buyItem(price)"
            >
              <b>หลักสูตรยอดนิยม</b>
            </p>
          </a>
        </div>
        <div class="col-3">
          <p class="mt-4 text-center" style="padding-left: 10px; color: #000">
            หลักสูตรใหม่
          </p>
        </div>
        <div class="col-3">
          <p class="mt-4 text-center" style="padding-left: 10px; color: #000">
            หลักสูตรสำหรับเด็ก
          </p>
        </div>
        <div class="col-3">
          <p class="mt-4 text-center" style="padding-left: 10px; color: #000">
            เอกสารอื่น ๆ
          </p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
      </div>
      <br>
      <div style="margin-top: 20px;">
        <h1 class="text-center">
          เพราะโลกไม่เคยหยุดหมุน เราจึงไม่หยุดเรียนรู้
        </h1>
      <img src="https://www.cpuniversityonline.com/images/landing/S8imgSuccess.webp" style="    width: 100%;" />
      </div>

      <br>
      <b-row align-h="center">
        <b-col md="6">
          <a class="card-img-wrapper rounded" href="javascript:void(0)">
            <!-- <b-embed type="video" controls>
              <source
                src="https://www.youtube.com/watch?v=xgOSHgZw8yU"
                type="video/mp4"
              />
            </b-embed> -->
            <youtube
              video-id="xgOSHgZw8yU"
              ref="youtube"
              style="width: 100%; height: 400px;"
              
            ></youtube>
          </a>
        </b-col>
        <b-col md="6">
          <a class="card-img-wrapper rounded" href="javascript:void(0)">
            <!-- <b-embed type="video" controls>
              <source
                src="https://dare-user-upload.s3.ap-southeast-1.amazonaws.com/videos/HBD+D.A.R.E.mov"
                type="video/mp4"
              />
            </b-embed> -->
            <youtube
              video-id="OWSCGkFmBv8"
              ref="youtube"
              style="width: 100%; height: 400px;"
              
            ></youtube>
          </a>
        </b-col>
      </b-row>
      <h3 class="mt-4">ข่าวประชาสัมพันธ์</h3>

      <div class="row mb-4">
        <!-- <news></news> -->
        <div class="col-3">
          <news-card style="width: 100%"> </news-card>
        </div>
        <div class="col-3">
          <news-card style="width: 100%"> </news-card>
        </div>
        <div class="col-3">
          <news-card style="width: 100%"> </news-card>
        </div>
        <div class="col-3">
          <news-card style="width: 100%"> </news-card>
        </div>
      </div>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../components/layout/PageContent";
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";

import ReasonCard from "../components/card/ReasonCard";
import NewsCard from "../components/card/NewsCard";

export default {
  components: {
    PageContent,
    // PageTitle,
    // BannerImageHome,
    // News,
    ReasonCard,
    NewsCard,
    // Reason,
  },

  data() {
    return {};
  },
  methods: {
    async reasonDetail() {
      location.href = "/reason-detail";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
