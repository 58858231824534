<template>
  <div>
    <b-card
      overlay
      img-src="https://yuzudigital.com/reason_mock.png"
      text-variant="white"
      footer-bg-variant="primary"
      footer-text-variant="white"
    >
      <div class="text-right">
        <b-button href="/reason-detail" variant="primary" style="padding: 2px;">
          <p class="m-0" style="font-size: 0.8rem;">ลงทะเบียน</p>
        </b-button>
      </div>
      <template #footer>
        <p>
          <b> English Language Company </b>
        </p>
      </template>
    </b-card>
  </div>
</template>

<script>
export default {
  
  methods: {
    async reasonDetail() {
      location.href = "/reason-detail";
    },
  }
};
</script>

